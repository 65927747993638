import { LoadingButton } from "@mui/lab";
import { Box, Link, TextField } from "@mui/material";
import { useState } from "react";
import {AuthPage} from '../../styles/Auth'
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useAuthRequests } from "../../helpers/AuthRequests";
import { useHistory } from "react-router-dom";
import mainlogowhite from '../../mainlogowhite.png'
import styled from "styled-components";

export default function ForgotPassword() {
  const { forgotSendVerifyCodeRequest,changePasswordWithVerifyCodeRequest } = useAuthRequests();
  const dispatch = useDispatch();
  const { login } = useSelector((state) => state.auth);
  
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [step, setStep] = useState(1);
  const [verifyCode, setVerifyCode] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const history = useHistory();

  const RedditTextField = styled((props) => (
    <TextField InputProps={{ disableUnderline: true }} {...props} />
  ))(({ theme }) => ({}));

  const sendVerifyCode = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    } else if (email.length < 2 && step === 1) {
      setError("Zorunlu alanları geçmeyiniz.");
    } else {
      setLoading(true);
      
      if (step === 1) {
        setError("");
        setSuccess("");
        forgotSendVerifyCodeRequest({ email: email })
          .then(async (response) => {
            setError("");
            setSuccess(response.data.message);
            setLoading(false);
            setTimeout(() => {
              setStep(2);
            },2000);
          })
          .catch((error) => {
            setError(error.response?.data?.message || "Bir hata oluştu");
            setLoading(false);
          });
      }
    }
  };

  const changePasswordWithVerifyCode = async (e) => {
    e.preventDefault();
  
    if (loading) return;
  
    if (newPassword.length < 1 || confirmPassword.length < 1) {
      setError("Zorunlu alanları geçmeyiniz.");
      return;
    }
  
    if (newPassword !== confirmPassword) {
      setError("Şifreler eşleşmiyor.");
      setLoading(false);
      return;
    }
  
    if (newPassword.length <= 6 || confirmPassword.length <= 6) {
      setError("Şifreniz en az 6 karakter olmalıdır.");
      setLoading(false);
      return;
    }
  
    setLoading(true);
    setError("");
    setSuccess("");
  
    try {
      // Şifre değiştirme işlemi
      await changePasswordWithVerifyCodeRequest({
        email: email,
        newPassword: newPassword,
        verifyCode: verifyCode,
      });
  
      setError("");
      setEmail("");
      setNewPassword("");
      setConfirmPassword("");
      setVerifyCode("");
      setLoading(false);
      setSuccess("Şifre başarıyla oluşturuldu!");
      setStep(3);
  
      // 2 saniye sonra yönlendirme
      setTimeout(() => {
        history.push("/giris-yap");
      }, 2000);
    } catch (error) {
      setLoading(false);
      setError(error.response?.data?.message || "Bir hata oluştu");
    }
  };
  

  return (
    <AuthPage>
      <Link to="/" className="logo" style={{ position: "absolute", top: 20 }}>
        <img
         onContextMenu={() => false}
         height={530}
         style={{marginTop:-160}}
         src={mainlogowhite}
        />
      </Link>
    <div style={{ height:'100%',width: "100%", display: "flex", justifyContent: "center", alignItems: "center", position: "relative" }}>
      
      {/* Logo */}
      
      
      {/* Form */}
      <div style={{ width: "100%", maxWidth: 400, padding: "20px", boxSizing: "border-box" }}>
        {step === 1 && (
          <>
          <form style={{marginBottom:'10px'}} onSubmit={sendVerifyCode}>
            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                style={{ backgroundColor: "white" }}
                label="E Posta Adresinizi Giriniz"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant="filled"
                type="email"
              />
            </Box>
            {error && <div style={{ color: "red", marginBottom: 10 }}>{error}</div>}
            <LoadingButton loading={loading} type="submit" variant="contained" fullWidth>
              Gönder
            </LoadingButton>
          </form>
           <a style={{ color: "white", cursor: "pointer"}} onClick={()=>history.push("/giris-yap")}>Giriş Yap</a>
           </>
          
        )}
    
        {step === 2 && (
        <>       
          <form style={{marginBottom:'10px'}} onSubmit={changePasswordWithVerifyCode}>
            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                style={{ backgroundColor: "white" }}
                label="Doğrulama Kodunu Giriniz"
                value={verifyCode}
                onChange={(e) => setVerifyCode(e.target.value)}
                variant="filled"
                type="text"
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                style={{ backgroundColor: "white" }}
                label="Yeni Şifrenizi Giriniz"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                variant="filled"
                type="password"
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                style={{ backgroundColor: "white" }}
                label="Yeni Şifreyi Onaylayınız"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                variant="filled"
                type="password"
              />
            </Box>
            {error && <div style={{ color: "red", marginBottom: 10 }}>{error}</div>}
            <LoadingButton loading={loading} type="submit" variant="contained" fullWidth>
              Şifreni Oluştur
            </LoadingButton>
           
          </form>
          <a style={{ color: "white", cursor: "pointer"}} onClick={()=>history.push("/giris-yap")}>Giriş Yap</a>
        </>
      
        )}
        {success && step === 1 && (
          <div style={{ color: "green", marginBottom: 10, backgroundColor: "white",padding:'10px' }}>{success}</div>
        )}
        {success && step === 3 && (
          <div style={{ color: "green", marginBottom: 10, backgroundColor: "white",padding: "10px" }}>{success}</div>
        )}
      </div>
    </div>
     
  </AuthPage>
  
  );
}
